import { isExistent } from '@aletheia/common/utils/guards'
import { ReferralMap } from '@aletheia/givingwhatwecan/components/utils/hooks/useReferrals'

import { Campaign } from '../../givingwhatwecan/components/Campaign/types'
import { Fundraiser } from '../../givingwhatwecan/components/Fundraiser/types'
import {
  GetPaymentsQueryResult,
  CreatePaymentMutationResult,
  GetRecurringPaymentsQueryResult,
  CreateRecurringPaymentMutationResult,
  GetGocardlessMandatesQueryResult,
  PaymentGateway,
  PaymentMethod,
  PaymentRecurrence,
  PaymentStatus,
  RecurrencePeriod,
  RecurringPaymentStatus,
  GetPaymentQueryResult,
} from '../graphql'

/** Payment node as returned by GetPaymentsQuery */
export type GetPaymentsQueryResultPaymentNode = NonNullable<
  NonNullable<
    NonNullable<GetPaymentsQueryResult['data']>['Payments']
  >['edges'][0]['node']
>

/** Payment node as returned by GetPaymentsQuery */
type GetPaymentQueryResultPayment = NonNullable<
  NonNullable<NonNullable<GetPaymentQueryResult['data']>['Payment']>
>

/** Payment node as returned by CreatePaymentMutation */
type CreatePaymentMutationResultPayment = NonNullable<
  NonNullable<
    NonNullable<CreatePaymentMutationResult['data']>['mutationResult']
  >['payment']
>

/**
 * A Payment result returned from the database, either by a query or a mutation
 *
 * Used for casting between the database result and a properly-formatted
 * TPayment
 */
export type TPaymentResult =
  | GetPaymentsQueryResultPaymentNode
  | GetPaymentQueryResultPayment
  | CreatePaymentMutationResultPayment

/**
 * Gift Aid Claim node as returned in the `giftAidClaimsByPaymentIdAndPersonId`
 * field of GetPaymentsQuery
 */
type GetPaymentsQueryResultGiftAidClaimNode = NonNullable<
  GetPaymentsQueryResultPaymentNode['giftAidClaimsByPaymentIdAndPersonId']['edges'][0]['node']
>

/** Recurring Payment node as returned by GetRecurringPaymentsQuery */
type GetRecurringPaymentsQueryResultRecurringPaymentNode = NonNullable<
  NonNullable<
    NonNullable<GetRecurringPaymentsQueryResult['data']>['RecurringPayments']
  >['edges'][0]['node']
>

/** Recurring Payment node as returned by CreateRecurringPaymentMutation */
type CreateRecurringPaymentMutationResultRecurringPayment = NonNullable<
  NonNullable<
    NonNullable<CreateRecurringPaymentMutationResult['data']>['mutationResult']
  >['recurringPayment']
>

/**
 * A Recurring Payment result returned from the database, either by a query or a
 * mutation
 *
 * Used for casting between the database result and a properly-formatted
 * TRecurringPayment
 */
export type TRecurringPaymentResult =
  | GetRecurringPaymentsQueryResultRecurringPaymentNode
  | CreateRecurringPaymentMutationResultRecurringPayment

/** Payment object after parsing/formatting */
export type TPayment = {
  __typename: 'Payment'
  id: string
  personId: string
  chargeCurrencyCode: ESupportedCurrencyCode
  chargeAmount: number
  currencyCode?: ESupportedCurrencyCode
  amount?: number
  net?: number
  fee?: number
  amountNormalized?: number
  recipient: EPaymentRecipient | ELegacyPaymentRecipient
  allocation: TAllocation
  gateway: PaymentGateway
  gatewayData?: TPaymentLikeGatewayData
  gatewayTransactionId?: string
  paymentMethod?: PaymentMethod
  recurrence: PaymentRecurrence
  metadata?: TPaymentLikeMetadata
  status: PaymentStatus
  statusData?: TPaymentLikeStatusData
  reference?: string
  billingAddressId?: string
  createdAt: string
  updatedAt: string
  _links: TPaymentLinks
}

/** Recurring Payment object after parsing/formatting */
export type TRecurringPayment = {
  __typename: 'RecurringPayment'
  id: string
  personId?: string
  period: RecurrencePeriod
  periodIndex: number
  periodInterval: number
  allocation: TAllocation
  amount: number
  currencyCode: string
  recipient: EPaymentRecipient | ELegacyPaymentRecipient
  gateway: PaymentGateway
  gatewayData?: TPaymentLikeGatewayData
  paymentMethod?: PaymentMethod
  metadata?: TPaymentLikeMetadata
  status: RecurringPaymentStatus
  statusData?: TPaymentLikeStatusData
  billingAddressId?: string
  createdAt: string
  updatedAt: string
}

/** Either a TPayment or TRecurringPayment */
export type TPaymentLike = TPayment | TRecurringPayment

/** Allowed shape of the Payment.metadata object */
export type TPaymentLikeMetadata = {
  gift_aid?: TPaymentLikeMetadataGiftAid
  share_with_partners?: TPaymentLikeMetadataPartnerSharing
  origin?: string
  country?: string
  target_currency_code?: string
  referral?: ReferralMap
  lottery?: TPaymentLikeMetadataLottery
  app_id?: string
  campaign?: TCampaignMetadata
  fundraiser?: TFundraiserMetadata
}

/** Shape of Payment.metadata.gift_aid */
export type TPaymentLikeMetadataGiftAid = {
  claimed: boolean
  address_id?: string
}

/** Shape of Payment.metadata.share_with_partners */
type TPaymentLikeMetadataPartnerSharing = {
  share: boolean
}

/** Shape of general metadata data sharing */
export type TPaymentLikeMetadataDataSharing = {
  share_full_name?: boolean
  share_email?: boolean
}

/** Shape of Payment.metadata.campaign */
type TCampaignMetadata = Campaign & {
  data_sharing?: TPaymentLikeMetadataDataSharing
}

/** Shape of Payment.metadata.fundraiser */
type TFundraiserMetadata = Fundraiser & {
  data_sharing?: TPaymentLikeMetadataDataSharing
}

type TPaymentLikeMetadataLottery = {
  anonymous: boolean
  lottery_id: string
}

/** Allowed shape of the Payment.gatewayData object */
export type TPaymentLikeGatewayData =
  | TPaymentLikeGatewayDataStripe
  | TPaymentLikeGatewayDataGoCardless
  | TPaymentLikeGatewayDataChariot
  | TPaymentLikeGatewayDataGiftAid

/** Shape of Payment.gatewayData when Payment.gateway = 'STRIPE' */
export type TPaymentLikeGatewayDataStripe = {
  payment_intent?: string
  payment_intent_client_secret?: string
  payment_method?: string
  subscription?: string
  card_last_4_digits?: string
}

/** Shape of Payment.gatewayData when Payment.gateway = 'GOCARDLESS' */
export type TPaymentLikeGatewayDataGoCardless = {
  mandate?: string
  subscription?: string
}

/** Shape of Payment.gatewayData when Payment.gateway = 'CHARIOT' */
export type TPaymentLikeGatewayDataChariot = {
  workflowSessionId?: string
  fundId?: string
  userFriendlyId?: string
}

/** Shape of Payment.gatewayData when Payment.gateway = 'GIFT_AID' */
export type TPaymentLikeGatewayDataGiftAid = {
  gift_aid?: {
    report_id?: string | number
    original_payment_id?: string | number
  }
}

/** Allowed shape of the Payment.statusData object */
export type TPaymentLikeStatusData = {
  error: string
  [x: string]: unknown
}

/** Objects that could be linked to a Payment node via a foreign key relationship */
type TPaymentLinks = {
  giftAidClaim?: GetPaymentsQueryResultGiftAidClaimNode
}

export type TAllocationItem = {
  organization: string
  percentage: number
  isExtra?: boolean
}
export type TAllocation = TAllocationItem[]

/** Enum describing a valid payment recipient that accept payments */
export enum EPaymentRecipient {
  'GWWC_US' = 'gwwc-us',
  'CEA_UK' = 'cea-uk',
  'RC_CA' = 'rc-ca',
  'EAA_AU' = 'eaa-au',
  'EAAE_AU' = 'eaae-au',
}

/** Enum describing legacy payment recipients that should not be used anymore,
 *  except for displaying historical information. */
export enum ELegacyPaymentRecipient {
  /** @deprecated Income migrated to GWWC USA on 2024-04-01.
   *              Only use this enum value to display data, do not accept new
   *              payments for this recipient */
  'CEA_US' = 'cea-us',
}

export const PAYMENT_RECIPIENT_MAPPING: { [key in EPaymentRecipient]: string } =
  {
    [EPaymentRecipient['GWWC_US']]: 'US',
    [EPaymentRecipient['CEA_UK']]: 'GB',
    [EPaymentRecipient['RC_CA']]: 'CA',
    [EPaymentRecipient['EAA_AU']]: 'AU',
    [EPaymentRecipient['EAAE_AU']]: 'AU',
  }

export const enabledPaymentRecipients = [
  EPaymentRecipient.CEA_UK,
  EPaymentRecipient.GWWC_US,
  process.env.NEXT_PUBLIC_FEATURE_FLAG_RC_ENABLED === 'true'
    ? EPaymentRecipient.RC_CA
    : undefined,
  process.env.NEXT_PUBLIC_FEATURE_FLAG_EAA_ENABLED === 'true'
    ? EPaymentRecipient.EAA_AU
    : undefined,
  process.env.NEXT_PUBLIC_FEATURE_FLAG_EAAE_ENABLED === 'true'
    ? EPaymentRecipient.EAAE_AU
    : undefined,
].filter(isExistent)

/* Enum describing a supported currency code */
export enum ESupportedCurrencyCode {
  'USD' = 'USD',
  'GBP' = 'GBP',
  'CAD' = 'CAD',
  'EUR' = 'EUR',
  'AUD' = 'AUD',
}

/** GoCardless Mandate */
export type TGocardlessMandate = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        GetGocardlessMandatesQueryResult['data']
      >['gocardlessMandates']
    >['edges'][0]
  >['node']
>
